<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <br />
        <v-dialog persistent v-model="isLoading">
            <v-row justify="center">
                <v-card width="400" >
                <center class="pa-5">
                   Importing Bill data.<br />Please Wait It will Take Time..
                </center>
                <div class="ma-6">
                    <v-progress-linear  v-model="percent" color="amber" height="25">
                        <template v-slot:default="{ value }">
                            <strong>{{ Math.ceil(value) }}%</strong>
                        </template>
                    </v-progress-linear>
                </div>
                </v-card>
            </v-row>
        </v-dialog>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            /
            <router-link :to="{name: 'admin-master' }" class="root">
                Admin Master
            </router-link>
            <span class="child">/ Book Bill </span>
        </v-row>
        <br />
         
        <v-card class="card">
            <v-card-title class="heading">Import Book Bill</v-card-title>
            <div class="add-section">
                <v-row class="justify-content-between set-btn">
                    <div><a href="https://library-public.s3.ap-south-1.amazonaws.com/sample_csv/library_import_bill.xlsx">
                        <v-btn class="mb-2 button"> <v-icon left dark>mdi-cloud-download</v-icon>Export XLSX Template</v-btn></a>
                    </div>
                    <div>
                        <v-btn class="button mb-2" @click="addBill"> <v-icon left>mdi-account-plus</v-icon>Add New Bill</v-btn>
                    </div>
                </v-row>
                <p class="file-warn">NOTE: *File should be in XLSX format.</p>

                <v-row class="justify-content-center">
                    <v-col lg="5" sm="12" md="5" class="import-box">
                        <label class="label">IMPORT New Bills XLSX</label>
                        <v-file-input dense v-model="file" :hint="file != null? '':'Please Select File'" persistent-hint color="rgb(1, 127, 152)" label="Choose File" accept=".csv" prepend-icon="mdi-paperclip" outlined show-size>
                        </v-file-input>
                        <v-btn @click="importCsv" :loading="output_load" class="button">
                            Import Bills
                            <v-icon right>mdi-cloud-upload</v-icon>
                        </v-btn>
                    </v-col>
                
                    <v-col lg="5" sm="12" md="5" class="import-box">
                        <label class="label">UPDATE Existing Bills XLSX</label>
                        <v-file-input dense v-model="fileupdate" :hint="fileupdate != null? '':'Please Select File'" persistent-hint color="rgb(1, 127, 152)" label="Choose File" accept=".csv" prepend-icon="mdi-paperclip" outlined show-size>
                        </v-file-input>
                        <v-btn @click="importCsvUpdate" :loading="output_load_update" class="button">
                            Update Bills
                            <v-icon right>mdi-cloud-upload</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

            <template>
                    <h5 v-if="failList.length > 0" class="mt-4" style="text-align: center; color: red;">Failed Record</h5>
                    <v-simple-table v-if="failList.length > 0">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Excel row no</th>
                                    <th>Error</th>
                                    <th>Bill No.</th>
                                    <th>Purchase Date</th>
                                    <th>Vendor/Source</th>
                                    <th>Price</th>
                                    <th>Discount</th>
                                    <th>Po No</th>
                                    <th>Po Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in failList" :key="item.name" style="color:red;">
                                    <th>{{item.rowno}} </th>
                                    <th>{{item.error}}  </th>
                                    <th>{{item.row.billnumber}} </th>
                                    <th>{{item.row.purchasedate}} </th>
                                    <th>{{item.row.vendor}} </th>
                                    <th>{{item.row.price}} </th>
                                    <th>{{item.row.discount}} </th>
                                    <th>{{item.row.ponumber}} </th>
                                    <th>{{item.row.podate}} </th>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>

                <template>
                    <h5 v-if="successList.length > 0" class="mt-4" style="text-align: center; color: green;">Success Record</h5>
                    <v-simple-table v-if="successList.length > 0">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Excel row no</th>
                                    <th>Bill No.</th>
                                    <th>Purchase Date</th>
                                    <th>Vendor/Source</th>
                                    <th>Price</th>
                                    <th>Discount</th>
                                    <th>Po No</th>
                                    <th>Po Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in successList" :key="item.name" style="color:green;">
                                    <th>{{item.rowno}} </th>
                                    <th>{{item.row.billnumber}} </th>
                                    <th>{{item.row.purchasedate}} </th>
                                    <th>{{item.row.vendor}} </th>
                                    <th>{{item.row.price}} </th>
                                    <th>{{item.row.discount}} </th>
                                    <th>{{item.row.ponumber}} </th>
                                    <th>{{item.row.podate}} </th>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>

         
            <template v-if="billlist!=null">
                <v-data-table :headers="headers" :items="billlist" :search="search" class="elevation-1 nowrap">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line hide-details></v-text-field>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.edit="{ item }">
                        <v-icon v-if="item" class="mr-2" @click="editItem(item)">mdi mdi-pencil-box</v-icon>
                    </template>
                    <template v-slot:item.delete="{ item }">
                        <v-icon v-if="item" class="mr-2" @click="deleteBill(item)"> mdi mdi-delete</v-icon>
                    </template> 
                </v-data-table>
            </template>

            <v-dialog v-model="bill_dialog" width="500">
                <v-card>
                        <v-card-title class="heading"> 
                            <v-row>
                                    <v-col cols="11" md="11" sm="11">
                                        {{ formTitle }} 
                                    </v-col>
                                    <v-col cols="1" md="1" sm="1">
                                    <v-btn icon dark @click="close">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <label class="add-text">Bill Number</label><span style="color: red; font-weight: bolder">*</span>
                                        <v-text-field dense outlined v-model="editedItem.billnumber" item-text="roleLink_list_id" item-value="id" :hint="editedItem.billnumber!=null?'':'Please enter Bill No.'" persistent-hint></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <label>Source/vendor</label> <span style="color: red; font-weight: bolder">*</span>
                                        <v-icon color="blue" class="mr-2" @click="vendor_dialog = true">mdi-plus-box</v-icon>
                                        <v-autocomplete :items="vendorlist" item-text="vendor" item-value="vendorid" v-model="editedItem.vendor" persistent-hint @input.native="searchVendorDropdown($event.srcElement.value)"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12">
                                        <label>Date of Purchase</label> <span style="color: red; font-weight: bolder">*</span>
                                        <v-menu v-model="showPickerPurchase" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="editedItem.purchasedate" placeholder="Select Date" hint="YYYY/MM/DD" persistent-hint dense readonly outlined v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="editedItem.purchasedate" no-title :min="nowDate" :max="getEndDate"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12">
                                        <label>Book Purchase price</label>
                                        <v-text-field dense outlined v-model="editedItem.price" item-text="roleLink_list_id" item-value="id" persistent-hint type="number" @input="calbill_discount()"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <label>Book Discount</label>
                                        <v-text-field dense outlined v-model="editedItem.discount" item-text="roleLink_list_id" item-value="id" persistent-hint type="number" @input="calbill_discount()"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <label>Book price</label>
                                        <v-text-field dense outlined v-model="editedItem.bookprice" item-text="roleLink_list_id" item-value="id" persistent-hint type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <label class="add-text">PO Number</label>
                                        <v-text-field dense outlined v-model="editedItem.ponumber" item-text="roleLink_list_id" item-value="id" persistent-hint></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <label>PO Date</label>
                                        <v-menu v-model="showPickerPO" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="editedItem.podate" placeholder="Select Date" hint="YYYY/MM/DD" persistent-hint dense readonly outlined v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="editedItem.podate" no-title :min="nowDate" :max="getEndDate"></v-date-picker>
                                        </v-menu>
                                    </v-col>                  
                                </v-row>
                            </v-container>
                            <small style="color: red;">*indicates required field</small>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn class="button-cancel" @click="bill_dialog = false">Close</v-btn>
                            <v-btn class="button" text @click="save">Save</v-btn>
                        </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="vendor_dialog" width="500">
                <v-card>
                    <v-card-title class="heading">
                        <v-row>
                            <v-col cols="11" md="11" sm="11">
                                Add Vendor 
                            </v-col>
                            <v-col cols="1" md="1" sm="1">
                                <v-btn icon dark @click="vendor_dialog = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                     <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <label class="add-text">Vendor Name</label><span style="color: red; font-weight: bolder">*</span>
                                    <v-text-field class="text" v-model="vendorname" outlined dense clearable required></v-text-field>
                                </v-col>
                                </v-row>
                        </v-container>
                        <small style="color: red;">*indicates required field</small>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="button-cancel" @click="vendor_dialog = false">Close</v-btn>
                        <v-btn class="button" text @click="addVendor">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>       
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; 
    export default {
        data() {
            return {
                overlay:false,
                snackbar_msg: "",
                snackbar: false,
                color: "",
                dialog: false,
                search: "",
                valid: false,
                nowDate: '0000-01-01',    
                vendor_dialog:false,
                vendorname:"",
                bill_dialog:false,
                billlist : [],
                vendorlist : [],
                showPickerPurchase:false,
                showPickerPO:false,
                editedItem: {
                    billnumber:"",
                    purchasedate:"",
                    ponumber : "",
                    podate : "",
                    price : 0,
                    discount: 0,
                    bookprice: 0,
                    vendor: "",
                    id: ""
                },

                billlist : [],
                headers: [
                    { text: "Sr no", value: "srno" },
                    { text: "Edit", value: "edit" },
                    { text: "Bill No.", value: "billnumber" },
                    { text: "Purchase Date", value: "purchasedate" },
                    { text: "Vendor/Source", value: "vendorname" },
                    { text: "Price", value: "price" },
                    { text: "Discount", value: "discount" },
                    { text: "Book Price", value: "bookprice" },
                    { text: "Po No.", value: "ponumber" },
                    { text: "PO Date", value: "podate" },
                    { text: "Delete", value: "delete" },
                ],

                editedIndex: -1,
                file: null,
                fileupdate: null,
                isLoading:false,
                failList: [],
                successList : [],            
                output_load: false,
                output_load_update : false,
                fileStatus: true,
                fileStatusupdate: true,
                count:-1,
                countupdate:-1,
                percent: 0,
                percentupdate: 0,
            };
        },

        computed: {
            getEndDate() {
                var endDate = new Date();
                return endDate.toISOString().slice(0,10)
            },
            formTitle() {
                return this.editedIndex === -1 ? "New Bill" : "Edit Bill";
            },
        },

        mounted() {
            this.onLoad();
        },

        methods: {
            addBill() {
                this.bill_dialog = true;
                this.editedIndex=-1;
            },

            editItem(item) {
                this.editedIndex = this.billlist.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.searchVendorDropdown(this.editedItem.vendorname);
                this.bill_dialog = true;
            },
            
            onLoad() {
                this.overlay = true
                axios
                    .post("/admin/getBills")
                    .then((res) => {
                        this.billlist = res.data.billlist;
                        this.overlay = false
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.overlay = false
                    });
            },

            save() {
                this.editedItem.billnumber = this.editedItem.billnumber.trim();
                if (this.editedItem.billnumber == null || this.editedItem.billnumber == "") {
                    this.showSnackbar("#b71c1c", "Please Enter Bill No. !!!");
                } else if (this.editedItem.vendor == null || this.editedItem.vendor == "") {
                    this.showSnackbar("#b71c1c", "Select Vendor/Source of Purchase !!!");
                } else if (this.editedItem.purchasedate == null || this.editedItem.purchasedate == "") {
                    this.showSnackbar("#b71c1c", "Select Date Of Purchase !!!");
                } else {
                    if (this.editedIndex > -1) {
                        axios
                        .post("/admin/editBill", this.editedItem)
                        .then((res) => {
                            if (res.data.msg == "success") {
                                this.overlay=false;
                                this.showSnackbar("#4caf50", "Bill Updated Successfully..."); 
                            } else {
                                this.overlay=false;
                                this.showSnackbar("#b71c1c", res.data.msg); 
                            }
                            this.onLoad();
                        })
                        .catch((error) => {
                            this.overlay=false;
                        });
                        this.close();
                    }else{
                        this.overlay=true;
                        axios
                            .post("/admin/addBill", this.editedItem)
                            .then((res) => {
                                if (res.data.msg == "success") {
                                    this.overlay=false;
                                    this.showSnackbar("green", "Bill Added Successfully.");
                                    this.onLoad(); 
                                    this.close();
                                } else {
                                    this.overlay=false;
                                    this.showSnackbar("#b71c1c", res.data.msg);
                                }
                            }).catch((error) => {
                                this.overlay=false;
                                this.showSnackbar("#b71c1c", "Something went wrong!!!");
                            }).finally(() => {
                                this.overlay=false;
                            });
                    }
                }
            },

            calbill_discount() {
                if(this.editedItem.discount==0){
                    this.editedItem.bookprice = this.editedItem.price;
                }else{
                    var discount = (this.editedItem.price*this.editedItem.discount)/100;
                    this.editedItem.bookprice = (this.editedItem.price-discount);
                }
            },

            close() {
                this.bill_dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },

            deleteBill(item){  
                if (confirm("Are You Sure You Want To Delete Bill Details?")) {                
                    const data = {            
                        id: item.id,
                    };
                    axios
                        .post("/admin/deleteBill", data)
                        .then((res) => {
                            if (res.data.msg == "200") {
                                this.showSnackbar("#4CAF50", "Bill Deleted Successfully  !!");
                                this.onLoad();
                            }else if(res.data.msg == "exp"){
                            this.showSnackbar("#b71c1c", "Can not be Deleted, Already In Use!!!");
                            
                        }
                        }).catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                            window.console.log(error);
                        });
                }
            },
 
            fileuploading(){
                let formData = new FormData();
                this.fileStatus = true;
                formData.append("file", this.file);
                formData.append("count", this.count);
                axios
                    .post("ImportData/importBill", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((res) => {
                        if (res.data.status == "200") {
                            if(this.count!=-1){
                                for(var i=0;i<res.data.successList.length;i++){
                                    this.successList.push( res.data.successList[i]);
                                }
                                for(var i=0;i<res.data.failList.length;i++){
                                    this.failList.push(res.data.failList[i]);
                                }
                            }
                            this.count=res.data.count;
                            this.totalbillsize=res.data.totalbillsize;
                            this.percent=(this.count/this.totalbillsize)*100;
                            if(res.data.isdone){
                                this.count=-1;
                                this.isLoading=false;
                                this.showSnackbar("#4caf50", "Bills added successfully...");
                                this.onLoad();
                            }else{
                                this.fileuploading();
                            }
                        }
                    })   
                    
            },

            importCsv() {
                this.failList=[];
                this.successList=[];
                if (this.file !== null) {
                   this.isLoading=true;
                   if (!(this.file.name.toLowerCase().includes(".xlsx") || this.file.name.toLowerCase().includes(".XLSX"))) {
                       this.showSnackbar("red", "File must be in xlsx format Only.!");
                   } else{
                        this.fileuploading();
                   }
                } else {
                    this.fileStatus = false;
                    this.showSnackbar("#b71c1c", "Please select file");
                }
            },


            fileuploadingUpdate(){
                let formData = new FormData();
                this.fileStatusupdate = true;
                formData.append("fileupdate", this.fileupdate);
                formData.append("countupdate", this.countupdate);
                axios
                    .post("ImportData/importExistingBill", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((res) => {
                        if (res.data.status == "200") {
                            if(this.countupdate!=-1){
                                for(var i=0;i<res.data.successList.length;i++){
                                    this.successList.push( res.data.successList[i]);
                                }
                                for(var i=0;i<res.data.failList.length;i++){
                                    this.failList.push(res.data.failList[i]);
                                }
                            }
                            this.countupdate=res.data.count;
                            this.totalbillsizeupdate=res.data.totalbillsize;
                            this.percentupdate=(this.count/this.totalbillsizeupdate)*100;
                            if(res.data.isdone){
                                this.countupdate=-1;
                                this.isLoading=false;
                                this.showSnackbar("#4caf50", "Bills Updated successfully...");
                                this.onLoad();
                            }else{
                                this.fileuploadingUpdate();
                            }
                        }
                    })   
                    
            },

            importCsvUpdate() {
                if (confirm("Are You Sure You Want To Update Bill Details?")) {
                        this.failList=[];
                        this.successList=[];
                        if (this.fileupdate !== null) {
                        this.isLoading=true;
                        if (!(this.fileupdate.name.toLowerCase().includes(".xlsx") || this.fileupdate.name.toLowerCase().includes(".XLSX"))) {
                            this.showSnackbar("red", "File must be in xlsx format Only.!");
                        } else{
                                this.fileuploadingUpdate();
                        }
                        } else {
                            this.fileStatusupdate = false;
                            this.showSnackbar("#b71c1c", "Please select file");
                        }
                }
            },
        
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },

            addVendor() {
                this.vendorname = this.vendorname.trim();
                if (this.vendorname == null || this.vendorname == "") {
                    this.showSnackbar("#b71c1c", "Please Enter Vendor/Source Name !!!");
                } else {
                    const data = {
                        vendorname: this.vendorname,
                    };
                    if (this.vendorname && this.vendorname!=""){
                        axios
                        .post("/admin/addVendor", data)
                        .then((res) => {
                            if (res.data.msg == "success") {
                                this.searchVendorDropdown(this.vendorname)
                                this.vendor_dialog = false;
                                this.vendorname = "";
                                this.showSnackbar("green", "Vendor Added Successfully.");
                            } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                            console.log(error);
                        })
                        .finally(() => {});
                    } else {
                        this.showSnackbar("#b71c1c", "Please enter required fields");
                    }
                }
            },

            searchVendorDropdown(value) {
                if (value != "") {
                    var params = { text: value};
                    axios
                    .post("/IssueBook/getsearchbyVendor", params)
                    .then((res) => {
                        if (res.data.msg == "200") {
                        this.vendorlist = res.data.vendorlist;
                        }
                    })
                }
            },
            
        },
    };
</script>

<style scoped>
    .downbtn {
        float: right;
    }
    .add-section {
        display: block;
    }
    .box {
        background-color: #add8e630;
        margin: 8px !important;
        padding: 8px;
    }
    .flex {
        display: flex;
        justify-content: space-between;
    }
    @media only screen and (max-width: 600px) {
        .flex {
            display: block;
        }
    }
    .btn {
        margin: 1rem;
        padding-top: 1rem;
    }
</style>
